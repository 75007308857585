<template>
  <tr>
    <td data-label="Product">
      <div class="ps-product--cart">
        <div class="ps-product__thumbnail">
          <a href="#">
            <img src="/img/product.png" alt="Product" />
          </a>
        </div>
        <div class="ps-product__content">
          <a href="#">{{ cartProduct.title }}</a>
          <p class="small text-muted">
            Sold By:
            <strong>
              <router-link :to="{ name: 'shop', params: { slug: cartProduct.shop.slug } }">{{ cartProduct.shop.name
                }}</router-link>
            </strong>
          </p>
        </div>
      </div>
    </td>
    <td class="price text-right" data-label="Price">{{ cartProduct.price }}</td>
    <td data-label="Quantity">
      <div class="form-group--number">
        <button class="up" v-on:click="addQuantity()">+</button>
        <button class="down" v-on:click="removeQuantity()">-</button>
        <input class="form-control" type="text" placeholder="1" v-model="quantity" />
      </div>
    </td>
    <td data-label="Fees" class="price text-right">
      {{ cartProduct.props.fees ?? 0.00 | toCurrency }}
    </td>
    <td data-label="Total" class="price text-right">
      {{ cartProduct.total | toCurrency }}
    </td>
    <td data-label="Actions" class="text-center">
      <a href="#" @click.prevent="removeCartProduct(cartProduct.id)">
        <i class="icon-cross"></i>
      </a>
    </td>
  </tr>
</template>

<script>
import global from "@/mixins/global";
import _ from "lodash";
export default {
  name: "CartProduct",
  mixins: [global],
  props: {
    cartProduct: Object,
  },
  data() {
    return {};
  },
  computed: {
    quantity() {
      return this.cartProduct.quantity;
    },
  },
  methods: {
    removeCartProduct(id) {
      if (confirm("Are you sure you want to remove")) {
        this.$store.dispatch("cart/removeCartProduct", {
          product: this.cartProduct,
        });
        // this.removeProductFromCart(id);
      }
    },
    addQuantity() {
      this.$store.dispatch("cart/addToCart", {
        product: this.cartProduct,
        quantity: 1,
      });
    },

    removeQuantity() {
      this.$store.dispatch("cart/removeFromCart", {
        product: this.cartProduct,
        quantity: 1,
      });
    },
  },
};
</script>

<style scoped></style>
